<!--
  服务详情:服务详情其他资源推荐的是服务(根据次服务id推荐其他服务)
    + 技术询问就是服务类型
    + 自己的服务无法下单
-->
<template>
  <div class="resource_detail" v-loading="loading">
    <div class="detail_left">
      <!-- 服务信息 -->
      <div class="service_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>资源服务详情</p>
        </div>
        <div class="module_box_main">
          <div class="main_header">
            <div class="header_left">
              <h1>{{ detail.title }}</h1>
              <div class="tag">{{ detail.serviceTypeName }}</div>
            </div>
            <div class="header_right">
              <div class="price_box">
                <template v-if="detail.fee === '面议'">
                  面议
                </template>
                <template v-else>
                  ￥<span>{{ detail.fee }}元</span>
                </template>
              </div>
              <div class="date_box">
                <span>发布时间 </span>
                <span>{{detail.createdAt | dateFormat}}</span>
              </div>
            </div>
          </div>
          <div class="main_content">
            {{ detail.description }}
          </div>
          <!-- 非本人（这里得orgInfo.id 是用户的id，里面有organization才表示此用户的机构）并且 服务状态为已发布 -->
          <div
            class="main_footer"
            v-if="curUserId !== orgInfo.id && detail.logicStatus === globalVar.SERVICE_STATUS_1">
            <el-button type="primary" @click="placeOrder(serviceId)">
              立即下单<i class="iconfont icongouwu tw-ml-5px"></i>
            </el-button>
            <div class="chat" @click="onChat">有问题？联系服务商家</div>
            <div class="report tw-text-12px" @click="onReport">
              <i class="iconfont iconjinggao"></i>
              <p>举报</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 自定义表单信息 -->
      <div class="other_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>其他信息</p>
        </div>
        <div class="module_box_main">
          <!-- 自定义表单 -->
          <custom-form
            ref="customForm"
            mode="static"
            :formModel="fieldList"
            :formData="detail">
            <custom-form-item
              v-for="item in fieldList"
              :key="item.column"
              :item="item"
              :value.sync="detail[item.column]">
            </custom-form-item>
          </custom-form>
        </div>
      </div>
      <!-- 机构信息 -->
      <router-link
        tag="a"
        :to="`/org-detail/${orgInfo.organizationId}`"
        target="_blank"
        class="org_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>机构介绍</p>
        </div>
        <div class="module_box_main">
          <div class="main_left">
            <h1>{{ orgInfo.orgName }}</h1>
            <div class="count_box">
              <div class="count_item">
                <h1 class="tw-text-primary">
                  {{ orgInfo.organization ? orgInfo.organization.avgScore : '' }}
                </h1>
                <p>信用评价</p>
              </div>
            </div>
          </div>
          <div class="main_right">
            <el-row type="flex">
              <div class="text_item">
                <div class="item_label">
                  联系人：
                </div>
                <div class="item_value">
                  {{ orgInfo.username }}
                </div>
              </div>
            </el-row>
            <el-row type="flex">
              <div class="text_item">
                <div class="item_label">
                  机构名称：
                </div>
                <div class="item_value">
                  {{ orgInfo.organization ? orgInfo.organization.name : '' }}
                </div>
              </div>
            </el-row>
            <el-row type="flex">
              <div class="text_item">
                <div class="item_label">
                  行业领域：
                </div>
                <div class="item_value">
                  {{ orgInfo.organization ? getTechnicalNames(orgInfo.organization.industryExpertise) : '' }}
                </div>
              </div>
            </el-row>
          </div>
        </div>
      </router-link>
      <!-- 服务链 -->
      <div class="service_chain module_box tw-mb-20px">
        <div class="module_box_header">
          <p>服务链</p>
        </div>
        <div class="module_box_main">
          <div class="service_chain_item" v-for="(item, index) in serviceChain" :key="item.id">
            <h1>所属服务链{{ index + 1 }}：</h1>
            <div class="chain_list">
              <div
                class="service_item"
                :class="{'cur_service':sItem.serviceId === detail.id}"
                v-for="(sItem, sIndex) in item.steps"
                :key="sItem.id">
                <span class="item_num">{{ sIndex + 1 }}</span>
                <div class="name_box">
                  <div class="item_name">
                    {{ sItem.serviceId ? sItem.serviceName : sItem.serviceTypeName }}
                  </div>
                  <p class="position">当前服务所在位置</p>
                </div>
              </div>
              <router-link tag="a" :to="`/service-chain-detail/${item.id}`" target="_blank" class="detail_link">查看详情</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务评价 -->
      <div class="service_comment module_box tw-mb-20px">
        <div class="module_box_header">
          <p>服务评价</p>
        </div>
        <div class="module_box_main">
          <div class="comment_item" v-for="item in commentList.data" :key="item.id">
            <div class="comment_item_left">
              <div class="comment_item_score">
                <div class="comment_item_score_item">
                  <p class="tw-text-14px">服务质量</p>
                  <el-rate :value="item.serviceQuality / 2" disabled ></el-rate>
                </div>
                <div class="comment_item_score_item">
                  <p class="tw-text-14px">服务效果</p>
                  <el-rate :value="item.serviceEffect / 2" disabled ></el-rate>
                </div>
                <div class="comment_item_score_item">
                  <p class="tw-text-14px">服务态度</p>
                  <el-rate :value="item.serviceAttitude / 2" disabled ></el-rate>
                </div>
              </div>
              <p class="comment_item_content tw-text-14px">{{item.content}}</p>
              <p class="comment_item_time tw-text-14px">{{item.createdAt}}</p>
            </div>
            <div class="comment_item_right">
              <user-avatar class="tw-flex-none" :size="40" :url="item.avatarUrl" />
              <p class="comment_item_name tw-text-14px">{{item.buyerName}}</p>
            </div>
          </div>
          <div class="comment_pagination">
            <el-pagination
              hide-on-single-page
              background
              @current-change="getCommentList"
              :current-page.sync="commentList.pagination.pageNum"
              :page-size="commentList.pagination.pageSize"
              layout="prev, pager, next, jumper"
              :total="commentList.pagination.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐 -->
    <div class="detail_right">
      <div class="recommend_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>其他相关资源推荐</p>
        </div>
        <div class="module_box_main">
          <router-link
            tag="a"
            :to="`/service-detail/${item.id}?from=recommend`"
            target="_blank"
            class="recommend_item"
            v-for="item in serviceRecommendList"
            :key="item.id">
            <div class="item_header">
              <h1>{{ item.title }}</h1>
              <p>
                <template v-if="item.fee === '面议'">
                  面议
                </template>
                <template v-else>
                  ￥{{ item.fee }}元
                </template>
              </p>
            </div>
            <div class="item_footer">
              <el-image
                v-if="item.avatarUrl"
                style="width: 24px; height: 24px"
                :src="item.avatarUrl"
                fit="cover">
              </el-image>
              <img v-else src="../../../assets/images/default_avatar.png" width="24" height="24" style="object-fit: cover;" />
              <p>{{ item.orgName }}</p>
            </div>
          </router-link>
        </div>
        <!-- <div class="module_box_footer">
          <div class="refresh_btn">
            换一批 <i class="iconfont iconshuaxin tw-ml-10px"></i>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 聊天窗口 -->
    <!-- <chat-btn
      v-if="orgInfo.id && curUserId !== orgInfo.id"
      ref="chatBtn"
      :seller="orgInfo" /> -->

    <!-- 举报窗口 -->
    <report-dialog
      :visible.sync="reportDialogVisible"
      :orgId="orgInfo.organizationId">
    </report-dialog>
  </div>
</template>

<script>
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'

import * as api from '@/api'
import reportDialog from './report-dialog.vue'

export default {
  components: {
    customForm,
    customFormItem,
    reportDialog
  },
  data () {
    return {
      loading: false,
      // 服务详情
      detail: {},
      // 机构详情
      orgInfo: {},
      // 字段数组
      fieldList: [],
      // 服务链
      serviceChain: [],
      // 服务推荐列表
      serviceRecommendList: [],
      commentList: {
        data: [],
        pagination: {
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      }, // 服务评价列表
      reportDialogVisible: false
    }
  },
  computed: {
    // 服务id
    serviceId () {
      return this.$route.params.id
    },
    // 来源 [recommend]
    from () {
      return this.$route.query.from || ''
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    }
  },
  created () {
    // 获取服务详情
    this.getServiceDetail()
    // 获取评价
    this.getCommentList(1)
  },
  methods: {
    // 获取服务详情
    getServiceDetail () {
      this.loading = true
      api.getServiceDetail(this.serviceId, {
        from: this.from
      }).then(res => {
        if (res.data.code === 0) {
          console.log('服务详情', res.data.data)
          this.detail = res.data.data.data
          this.orgInfo = res.data.data.orgInfo
          this.fieldList = res.data.data.fields.filter(item => {
            return !item.isFixed
          })
          this.serviceChain = res.data.data.serviceChain
          this.getServiceRecommendList(this.detail.id)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取服务推荐列表
    getServiceRecommendList (id) {
      api.getServiceRecommendList({
        srvId: id
      }).then(res => {
        if (res.data.code === 0) {
          console.log('服务推荐列表', res.data.data)
          this.serviceRecommendList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 获取服务的订单评价
    getCommentList (pageNum) {
      api.getServiceCommentList(this.serviceId, {
        pageNum,
        pageSize: this.commentList.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.commentList.pagination.pageNum = res.data.data.currentPage
          this.commentList.pagination.total = res.data.data.total
          this.commentList.data = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },

    // 下单
    placeOrder (serviceId) {
      this.$confirm('下单后会有工作人员与您联系', '确认下单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.createServiceOrder({
          serviceId
        }).then(res => {
          if (res.data.code === 0) {
            this.$message.success('下单成功')
            // 重新获取详情
            this.getServiceDetail()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.$message.error('请求出错')
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 打开聊天界面
    onChat () {
      let { id, avatarUrl, mobile } = this.orgInfo
      id = encodeURIComponent(id)
      avatarUrl = avatarUrl ? encodeURIComponent(avatarUrl) : ''
      mobile = encodeURIComponent(mobile)
      const { href } = this.$router.resolve({
        path: '/chat',
        query: {
          sellerId: id,
          sellerAvatar: avatarUrl,
          sellerMobile: mobile
        }
      })

      window.open(href, '_blank')
      // this.$refs.chatBtn.showDialog = true
    },
    // 举报
    onReport () {
      this.reportDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .resource_detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .detail_left {
      flex: 1;
      margin-right: 20px;
      .service_info {
        .module_box_main {
          padding-bottom: 30px;
          .main_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 67px;
            border-bottom: 1px solid #DCDFE6;
            .header_left {
              display: flex;
              height: 100%;
              align-items: center;
              & > h1 {
                font-size: 26px;
                font-weight: bold;
                line-height: 37px;
                color: #000000;
                margin-right: 30px;
              }
              .tag {
                height: 20px;
                padding: 0 5px;
                background: rgba(52, 115, 230, 0);
                border: 1px solid #DCDFE6;
                border-radius: 2px;
                font-size: 12px;
                font-weight: bold;
                line-height: 20px;
                color: #3473E6;
                text-align: center;
              }
            }
            .header_right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 500px;
              height: 100%;
              padding: 0 20px;
              background-image: url('../../../assets/images/detail/price_bg.png');
              background-repeat: no-repeat;
              .price_box {
                font-size: 16px;
                font-weight: bold;
                line-height: 33px;
                color: #FF4343;
                & > span {
                  font-size: 24px;
                }
                .date_box {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  color: #303133;
                  & > span {
                    &:first-child {
                      margin-right: 20px;
                    }
                  }
                }
              }
              // .date_box {

              // }
            }
          }
          .main_content {
            padding: 24px 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            color: #303133;
          }
          .main_footer {
            display: flex;
            align-items: center;
            .chat {
              font-size: 12px;
              font-weight: 500;
              line-height: 17px;
              color: #C0C4CC;
              margin-left: 20px;
              cursor: pointer;
            }
            .report {
              width: 60px;
              height: 24px;
              padding: 0 10px 0 8px;
              border-radius: 2px;
              @apply tw-flex tw-items-center tw-justify-between tw-ml-auto tw-mr-10px;
              @apply tw-text-secondary-text tw-text-12px;
              & > i {
                font-size: 12px;
              }
              &:hover {
                @apply tw-cursor-pointer tw-bg-danger tw-bg-opacity-10 tw-text-danger;
              }
            }
          }
        }
      }
      .org_info {
        background: #fff url('../../../assets/images/detail/about_the_company.png') right bottom no-repeat;
        .module_box_main {
          display: flex;
          padding-bottom: 30px;
          .main_left {
            margin-right: 60px;
            & > h1 {
              font-size: 20px;
              font-weight: bold;
              line-height: 60px;
              color: #000000;
              margin-right: 10px;
              & > i {
                color: #3473E6;
              }
            }
            .count_box {
              position: relative;
              display: flex;
              width: 130px;
              height: 70px;
              background-color: #F7F8F9;
              border-radius: 4px;
              .count_item {
                flex: 1;
                text-align: center;
                padding: 10px 0;
                & > h1 {
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 30px;
                }
                & > p {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                  color: #303133;
                }
              }
            }
          }
          .main_right {
            .text_item {
              display: flex;
              // max-width: 320px;
              font-size: 14px;
              line-height: 30px;
              margin-bottom: 10px;
              .item_label {
                // flex: 0 0 90px;
                width: 100px;
                color: #909399;
              }
              .item_value {
                // flex: 0 0 230px;
                width: 280px;
                color: #303133;
              }
            }
          }
        }
      }
      .service_chain {
        .module_box_main {
          padding-bottom: 30px;
          .service_chain_item {
            & > h1 {
              font-size: 14px;
              font-weight: 500;
              line-height: 30px;
              color: #909399;
              margin-bottom: 10px;
            }
            .chain_list {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              .service_item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding-bottom: 30px;
                .item_num {
                  width: 16px;
                  height: 16px;
                  text-align: center;
                  line-height: 16px;
                  color: #606266;
                  background: #ffffff;
                  border: 1px solid #dcdfe6;
                  border-radius: 50%;
                  transform: scale(0.83);
                  margin-right: 6px;
                }
                .name_box {
                  position: relative;
                  .item_name {
                    text-align: center;
                    height: 32px;
                    line-height: 32px;
                    background: #F7F8F9;
                    border: 1px solid #DCDFE6;
                    border-radius: 2px;
                    padding: 0 10px;
                    font-size: 14px;
                    color: #606266;
                  }
                  .position {
                    display: none;
                    position: absolute;
                    left: 0px;
                    bottom: -30px;
                    width: 100px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #C0C4CC;
                  }
                }
                &+.service_item {
                  &::before {
                    content: "";
                    display: inline-block;
                    width: 40px;
                    height: 1px;
                    margin: 0 10px;
                    border-top: 1px dashed #707070;
                  }
                }
                &:last-child {
                  .item_num, .item_name {
                    background-color: rgba(52, 115, 230, 0.1);
                    color: #3473E6;
                    border-color: #3473E6;
                  }
                }
                &.cur_service {
                  .position {
                    display: block;
                  }
                  .item_num {
                    background-color: rgba(52, 115, 230, 0.1);
                    color: #3473E6;
                    border-color: #3473E6;
                  }
                  .item_name {
                    background-color: #3473E6;
                    color: #fff;
                    border-color: #3473E6;
                  }
                }
              }
              .detail_link {
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                color: #3473E6;
                margin-left: 40px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      .service_comment {
        .module_box_main {
          .comment_item {
            display: flex;
            padding: 30px 0 20px 0;
            border-bottom: 1px solid #DCDFE6;
            &:first-child {
              padding-top: 0;
            }
            &_left {
              flex: 1;
            }
            &_score {
              height: 40px;
              display: flex;
              align-items: center;
              &_item {
                flex: 1;
                display: flex;
                align-items: center;
                & > p {
                  width: 85px;
                  color: #909399;
                }
              }
            }
            &_content {
              color: #606266;
              margin: 16px 0 20px 0;
              line-height: 20px;
              word-break: break-all;
            }
            &_time {
              color: #909399;
              line-height: 20px;
            }
            &_right {
              display: flex;
              align-items: center;
              width: 250px;
              padding: 0 10px 0 95px;
            }
            &_name {
              color: #606266;
              line-height: 20px;
              margin: 0 0 0 16px;
            }
          }
          .comment_pagination {
            flex: 1;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
    .detail_right {
      flex: 0 0 450px;
      .recommend_list {
        .module_box_main {
          padding-bottom: 20px;
          .recommend_item {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px dashed #DCDFE6;
            cursor: pointer;
            .item_header {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              & > h1 {
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                color: #606266;
                padding-right: 20px;
              }
              & > p {
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #909399;
              }
            }
            .item_footer {
              display: flex;
              align-items: center;
              height: 30px;
              & > p {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #909399;
                margin-left: 10px;
              }
            }
            &+.recommend_item {
              margin-top: 10px;
            }
          }
        }
        .module_box_footer {
          padding-bottom: 20px;
          .refresh_btn {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3473E6;
            cursor: pointer;
          }
        }
      }
    }

    .module_box {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden;
      .module_box_header {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
          margin-right: 10px;
        }
        & > p {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #31394C;
        }
      }
      .module_box_main {
        padding: 0 10px;
      }
      .module_box_footer {
        padding: 0 10px;
      }
    }
  }
</style>
